a {
	font-weight: normal;
}
header {
	position: fixed;
	width: 100%;
	height: 5.125rem;
	background: $dark-gray;
	padding: 1rem;
	color: $black;
	font-size: 1rem;
	font-weight: bold;
	letter-spacing: 1.5;
	z-index: 1;
	.logo {
		max-width: 100%;
		max-width: 12rem;
	}
	.phone a {
		position: relative;
		top: 0.25rem;
		color: $white;
	}
}

section {
	@include breakpoint(large){
		padding: 8.5rem 0;
	}
	padding: 2rem 0;
}

.hero {
	padding: 5.125rem 0 0;
	position: relative;
	background: url('../img/hero.jpg');
	background-size: cover;
	background-position: top;
	.hero-text {
		background: rgba($black, 0.77);
		padding: 10vh 0 10vh;
		@include breakpoint(large) {
			padding: 9.5rem 0 10.5rem;
		}
	}
	h1 {
		//font-size: 2.5rem;
		color: $white;
		margin-bottom: 2.5rem;
	}
	.button {
		font-size: 1.5rem;
		font-family: $header-font-family;
		margin-bottom: 0;
	}
}

.heres-how, .pricing {
	overflow-x: hidden;
}

.heres-how {
	background: $medium-gray;
	@include breakpoint(large) {
		padding: 8.5rem 0 7.5rem;
	}
	h2 {
		line-height: 1;
		margin: 0 0 1rem;
		@include breakpoint(medium) {
			margin: 0 0 2.5rem;
		}
		@include breakpoint(large) {
			margin: 0 0 5.625rem;
		}
	}
	ul {
		list-style: none;
		margin: 0;
	}
	li {
		padding: 0 12.5% 2rem 1rem;
		line-height: 1.2;
		@include breakpoint(large) {
			font-size: 1.25rem;
		}
		&.mui-enter-active {
			opacity: 1;
		}
	}
}

.pricing {
	color: $black;
	@include breakpoint(large) {
		font-size: 1.25rem;
		h2 {
			font-size: 3rem;
		}
	}
	.pricing-tables {
		margin: 1rem 0;
		@include breakpoint(large) {
			margin: 2.5rem 0;
		}
	}
	table {
		border-collapse: collapse;
		@include breakpoint(medium) {
			td {
				padding: 0.5rem 2rem;
			}
		}
	}
	thead {
		font-family: $header-font-family;
		@include breakpoint(medium) {
			font-size: 1.5rem;
		}
		td {
			background: $black;
			border-top-left-radius: $global-radius;
			border-top-right-radius: $global-radius;
			color: $white;
			font-weight: normal;
			@include breakpoint(medium) {
				padding: 1.5rem 2rem;
			}
		}
	}
	tbody {
		@include breakpoint(large) {
			font-size: 1.125rem;
		}
		tr:first-child {
			font-weight: bold;
			td {
				@include breakpoint(medium) {
					padding: 1.5rem 2rem;
				}
			}
		}
	}
	tfoot {
		font-family: $header-font-family;
		color: $black;
		background: $table-background;
		padding: 1.5rem 2rem;
		@include breakpoint(medium) {
			font-size: 2.25rem;
		}
		td {
			border-bottom-left-radius: $global-radius;
			border-bottom-right-radius: $global-radius;
		}
		span {
			vertical-align: top;
			@include breakpoint(large) {
				font-size: 1.25rem;
			}
		}
		a {
			display: block;
			margin-top: 1.25rem;
			padding: 1.25rem;
			@include breakpoint(large) {
				font-size: 1.25rem;
			}
		}
	}
}

.contact {
	background: $black;
	@include breakpoint(large) {
		font-size: 1.25rem;
	}
	h2 {
		color: $white;
		@include breakpoint(large) {
			font-size: 3rem;
		}
	}
	p {
		margin-bottom: 2rem;
	}
}

footer {
	background: $black;
	padding-bottom: 5rem;
	@include breakpoint(large) {
		font-size: 1.125rem;
	}
	a {
		color: $secondary-color;
	}
}